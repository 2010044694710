<template>
  <div :class="{ 'container-fluid p-0': true, 'admin-login': showLoginElements }">
    <Loading />
    <Toast />
    <SideMenu v-if="showLoginElements" />
    <RouterView v-slot="{ Component }">
      <transition :name="showLoginElements ? 'fade' : 'out-in'">
        <div class="main-container" v-if="showLoginElements">
          <Header v-if="showLoginElements" />
          <div class="container-body mt-4">
            <Component :is="Component" />
          </div>
        </div>
        <div v-else>
          <Component :is="Component" />
        </div>
      </transition>
    </RouterView>
    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
    <vue-progress-bar v-html="spinner"></vue-progress-bar>
  </div>
</template>

<script>
import Toast from '@/components/includes/Toast.vue'
import Loading from '@/components/includes/Loading.vue'

import Header from '@/components/includes/Header.vue'
import SideMenu from '@/components/includes/SideMenu.vue'

export default {
  name: 'App',
  components: {
    Toast,
    Loading,
    SideMenu,
    // eslint-disable-next-line vue/no-reserved-component-names
    Header
  },
  computed: {
    spinner () {
      return '<div id="spinner" class="spinner-border text-primary"></div>'
    },
    user () {
      return this.$store.getters['User/user']
    },
    showLoginElements () {
      return (
        this.user &&
        this.user.account_plan &&
        this.user.account_plan.status === 'paid' &&
        this.$route.name !== 'AddAccount' &&
        this.$route.name !== 'Welcome'
      )
    }
  }
}
</script>
<style>
#spinner {
  display: block;
  width: 24px;
  height: 24px;
  position: fixed;
  right: 8px;
  top: 8px;
}
</style>
