<template>
    <nav id="sidebar" class="admin-navbar">
        <div class="scrollable">
            <div class="main-menu pt-3">
                <div class="site-logo text-center mb-4">
                    <img :src="mediaBaseURL + 'assets/images/logo.png'" alt="">
                </div>
                <div class="navbar-logo">
                    <a class="mobile-menu" id="mobile-collapse" href="#!">
                        <i class="feather icon-menu icon-toggle-right"></i>
                    </a>
                    <router-link :to="{name: 'Dashboard'}" class="w-100 email-init my-4">
                        <!-- <img :src="mediaBaseURL + 'assets/images/favicon_192.png'" alt="" class="border p-2 rounded-circle"> -->
                        <span class="h3 border rounded-circle"> {{ user.email.charAt(0) }} </span>
                    </router-link>
                    <a class="mobile-options">
                        <i class="feather icon-more-horizontal"></i>
                    </a>

                </div>
                <div class="text-center user-email">
                    {{ user.email }}
                </div>
                <ul class="list-unstyled components my-3">
                    <li v-if="typeof access_modules['games'] != 'undefined' || user.type == 'account' ">
                        <router-link :to="{name:'Dashboard'}" class="py-2"><i class="las la-home h4 mb-0 menuicon"></i>
                            <span class="pl-3">Dashboard</span>
                        </router-link>
                    </li>
                    <li v-if="typeof access_modules['games'] != 'undefined' || user.type == 'account' ">
                        <a href="#templatesMenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed py-2">
                            <i class="las la-layer-group h4 mb-0 menuicon"></i>  <span class="pl-3">Templates</span>
                        </a>
                        <ul class="list-unstyled collapse" id="templatesMenu" style="">
                            <li>
                                <router-link class="py-2" :to="{name:'GameOverTemplateList'}"><span class="pl-4">Game Over</span></router-link>
                            </li>
                            <li>
                                <router-link class="py-2" :to="{name:'SuccessTemplateList'}"><span class="pl-4">Success</span></router-link>
                            </li>
                            <li>
                                <router-link class="py-2" :to="{name:'LeaderboardTemplateList'}"><span class="pl-4">Leaderboard</span></router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-if="typeof access_modules['games'] != 'undefined' || user.type == 'account' ">
                        <router-link :to="{name:'GameList'}" class="py-2"><i class="las la-gamepad h4 mb-0 menuicon"></i>
                            <span class="pl-3">Games</span>
                        </router-link>
                    </li>
                    <li v-if="typeof access_modules['rewards'] != 'undefined' || user.type == 'account' ">
                        <router-link :to="{name:'RewardList'}" class="py-2"><i class="las la-trophy h4 mb-0 menuicon"></i>
                            <span class="pl-3">Rewards</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="#settingsMenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed py-2">
                            <i class="las la-cog h4 mb-0 menuicon"></i>  <span class="pl-3">Settings</span>
                        </a>
                        <ul class="list-unstyled collapse" id="settingsMenu" style="">
                            <!-- <li v-if="(typeof access_modules['teams'] != 'undefined' && user.type == 'user') || (user.type == 'account')">
                                <router-link class="py-2" :to="{name:'TeamMates'}">
                                    <span class="pl-4">Team Mates</span>
                                </router-link>
                            </li>
                            <li v-if="(user.type == 'user' && typeof access_modules['domains'] != 'undefined') || (user.type == 'account')">
                                <router-link  class="py-2" :to="{name:'Domains'}">
                                    <span class="pl-4">Domains</span>
                                </router-link>
                            </li> -->
                            <li v-if="typeof access_modules['integrations'] != 'undefined' || user.type == 'account' ">
                                <router-link class="py-2" :to="{name:'Integrations'}"><span class="pl-4">Integrations</span></router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-if="user.plan.crm == 1 ">
                        <router-link :to="{name:'CRMList'}" class="py-2"><i class="fa fa-id-card"></i>
                            <span class="pl-3">User Data</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <a href="#" class="py-2"><i class="las la-info h4 mb-0 menuicon"></i> <span class="pl-3">Knowledge Base</span> </a>
                    </li> -->
                </ul>
            </div>
            <div class="footer mt-4" v-if="(user.type == 'user' && typeof access_modules['account_managment'] != 'undefined') || (user.type == 'account')">
                <ul v-if="user_limits && user">
                    <li class="impressions">
                        <span class="h6 mr-1 font-weight-normal">{{ user_limits.impressions }} / {{ user.account_plan.monthly_total_impressions }}</span> Gameplays
                        <div class="bar_cover mt-1">
                            <div class="bar">
                                <div class="used" :style="{ width: ((user_limits.impressions * 100) / user.account_plan.monthly_total_impressions) + '%' }">&nbsp;</div>
                            </div>
                        </div>
                    </li>
                    <li class="rewards-bar">
                        <span class="h6 mr-1 font-weight-normal">{{ user_limits.reward }} / {{ user.plan.rewards }}</span> Rewards
                        <div class="bar_cover mt-1">
                            <div class="bar">
                                <div class="used" :style="{ width: ((user_limits.reward * 100) / user.plan.rewards) + '%' }">&nbsp;</div>
                            </div>
                        </div>
                    </li>
                    <li class="domains">
                        <span class="h6 mr-1 font-weight-normal">{{ user_limits.domains }} / {{ user.plan.domains }}</span> Domains
                        <div class="bar_cover mt-1">
                            <div class="bar">
                                <div class="used" :style="{ width: ((user_limits.domains * 100) / user.plan.domains) + '%' }">&nbsp;</div>
                            </div>
                        </div>
                    </li>
                    <li class="users">
                        <span class="h6 mr-1 font-weight-normal">{{ user_limits.users }} / {{ user.plan.users }}</span> Team mates
                        <div class="bar_cover mt-1">
                            <div class="bar">
                                <div class="used" :style="{ width: ((user_limits.users * 100) / user.plan.users) + '%' }">&nbsp;</div>
                            </div>
                        </div>
                    </li>
                    <li class="sub-accounts">
                        <span class="h6 mr-1 font-weight-normal">{{ user_limits.sub_accounts }} / {{ user.plan.sub_accounts }}</span> Sub Accounts
                        <div class="bar_cover mt-1">
                            <div class="bar">
                                <div class="used" :style="{ width: ((user_limits.sub_accounts * 100) / user.plan.sub_accounts) + '%' }">&nbsp;</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <router-link class="btn btn-primary w-75 mx-auto text-light py-2" :to="{name:'Upgrade'}" >
                    Upgrade
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
  name: 'SideMenu',
  computed: {
    access_modules () { return this.$store.getters['User/access_modules'] },
    user () { return this.$store.getters['User/user'] },
    user_limits () { return this.$store.getters['User/user_limits'] }
  },
  data () {
    return {
      mediaBaseURL: import.meta.env.VITE_MEDIA_BASE_URL
    }
  }
}
</script>
